import './summary.css'
import useFoodStore from './hooks/foodstore.js';

const Summary = ({backclick}) => {
    
    const { selectedFoods} = useFoodStore();
    
    // Total Food Price
    const calculateGrandTotal = () => {
        let grandTotal = 0;
        selectedFoods.forEach(food => {
            grandTotal += food.quantity * food.price;
        });
        return grandTotal;
    }

    const calculateGST = () => {
        const grandTotal = calculateGrandTotal(); 
        const gstAmount = grandTotal * 0.18;
        return Math.floor(gstAmount);
    };

    const grossTotal = ()=> calculateGrandTotal() + calculateGST()


    return (
        <>  
            <div className='backtoedit' onClick={()=>backclick(false)} >Edit Order</div>

            <div className='selected-foods-container'>
                {/* Column headers */}
                <div className='selected-food-header'>
                    <span className='food-name-header'>Food Name</span>
                    <span className='food-quantity-header'>Qty</span>
                    <span className='food-price-header'>Total Price</span>
                </div>
                {/* Foods */}
                <div className='selected-food-items'>
                    <div className='selected-food-items-2'>
                    {selectedFoods.map((food, index) => (
                        <div key={index} className='selected-food'>
                            <span className='food-name'>{food.name}</span>
                            <span className='food-quantity'>{food.quantity}</span>
                            <span className='food-price'>₹ {food.quantity * food.price}</span>
                        </div>
                    ))}
                    </div>
                </div>
                
            </div>
            <div className='total-container'>
                {/* GRAND TOTAL */}
                <div className='grand-total'>
                    <span className='total-label'>Total</span>
                    <span className='total-amount'>₹ {calculateGrandTotal()}</span>
                </div>

                {/* GST % */}
                <div className='grand-total'>
                    <span className='total-label'>GST</span>
                    <span className='total-amount' >₹ {calculateGST()}</span>
                </div>

                {/* GROSS TOTAL */}
                <div className='grand-total'>
                    <span className='total-label'>Gross Total</span>
                    <span className='total-amount' >₹ {grossTotal()}</span>
                </div>
            </div>

        </>
  )
}

export default Summary