import {create} from 'zustand';

//CREATING STORE
const useFoodStore = create((set) => ({

    selectedFoods: [],
    
    addFoodItem: (foodItem) =>
        set((state) => ({ selectedFoods: [...state.selectedFoods, foodItem] })),

    removeFoodItem: (foodItemName) =>
        set((state) => ({
            selectedFoods: state.selectedFoods.filter(
                (item) => item.name !== foodItemName
            ),
        })),

    updateQuantity: (foodItemName, quantity) =>
        set((state) => ({
            selectedFoods: state.selectedFoods.map((item) =>
                item.name === foodItemName ? { ...item, quantity } : item
            ),
        })),

    clearAllFoodItems: () => set({ selectedFoods: [] }),
    
}));

export default useFoodStore;
