import './Home.css'
import './Category.css'
import Foods from '../JSON/Food_Items.json' //FOOD LIST JSON
import React, { useState } from 'react'
import FoodItems from './FoodItems'
import Summary from './Summary.js'
import Popup from './Popup.js'
import useFoodStore from './hooks/foodstore.js';


// CATEGORY ICON COMPONENT
const CatagoryIcon = ({ name, icon, selectedCategory, setSelectedCategory }) => {
    const handleClick = () => {
        if (selectedCategory === name) {
            setSelectedCategory(null); // Deselect the category if it's already selected
        } else {
            setSelectedCategory(name); // Set the selected category when the icon is clicked
        }
    };

    return (
        <div className={`catagory-container ${selectedCategory === name ? 'selected' : ''}`} onClick={handleClick}>
            <img src={`./ui_elements/category/${icon}`} alt='category' />
            <div className='category-footer'>{name}</div>
        </div>
    );
}


const Home = () => {

    const { clearAllFoodItems } = useFoodStore();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [placeOrderClicked, setPlaceOrderClicked] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const backHandle = (value)=>{ setPlaceOrderClicked(value) }
    const popupCloseHandle = () =>{setShowPopup(false) }
    const checkout = () =>{
        setShowPopup(true)
        setSelectedCategory(null)
        setSearchQuery('')
        setShowSearchResults(false)
        setPlaceOrderClicked(false)
        clearAllFoodItems()
    }

    /// Filtered food items based on search query
    const filteredItems = selectedCategory
    ? Foods.fooditems[selectedCategory].items.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : Object.values(Foods.fooditems)
        .flatMap(category => category.items)
        .filter(item =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

    const handleSearchIconClick = () => {
        setShowSearchResults(true);
    };

    return (

        // HOME CONTAINER
        <div className='bg' >
            <div className="flexbox-one">

                {/* BG IMAGE & LOGO */}
                <div className="bg-doodle"></div>
                <img src='./ui_elements/soulstories.webp' alt='soulstories logo' className='soulstories-logo'/>

                {/* SEARCH BOX */}
                <div className="search-container">
                    <input 
                        type="text" 
                        placeholder={selectedCategory ? (`Search in ${selectedCategory.toLowerCase()}`) : ('Search...')}
                        className="search-input" 
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <img src="./ui_elements/search.svg" alt="Search Icon" className="search-icon"  onClick={handleSearchIconClick}/>
                </div>
                
                {/* CATEGORY CONTAINER */}
                <div className="scrollable-container">
                    <div className="scrollable-content">  
                        {Object.keys(Foods.fooditems).map(categoryName => (
                            <CatagoryIcon 
                                key={categoryName} 
                                name={categoryName} 
                                icon={Foods.fooditems[categoryName].iconname} 
                                selectedCategory={selectedCategory} 
                                setSelectedCategory={setSelectedCategory} 
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className="flexbox-two" >

                {/* FOOD ITEMS */}
                <div className='food-container' >
                    <div className='food-text' >{placeOrderClicked ? 'Summary' : selectedCategory ? selectedCategory : 'FOOD ITEMS'}</div>
                    <img className='food-text-img' src='./ui_elements/category.webp' alt='food header' />

                    {!placeOrderClicked && (
                    <div className='food-items-container' >
                        {showSearchResults && (
                            filteredItems.map((item, index) => (
                                <FoodItems key={index} name={item.name} price={item.price} />
                            ))
                        )}
                        {!showSearchResults && selectedCategory && (
                            Foods.fooditems[selectedCategory].items.map((item, index) => (
                                <FoodItems key={index} name={item.name} price={item.price} />
                            ))
                        )}
                        {!showSearchResults && !selectedCategory && (
                            Object.values(Foods.fooditems)
                                .flatMap(category => category.items)
                                .map((item, index) => (
                                    <FoodItems key={index} name={item.name} price={item.price} />
                            ))
                        )}
                        {filteredItems.length === 0 && <div className="no-results">No results found</div>}
                    </div>
                    )}

                    {/* ORDER DETAILS */}
                    {placeOrderClicked && (
                        <Summary backclick = {backHandle}/>
                    )}
                    
                </div>
            </div>
            
            {/* PLACE ORDER BUTTON */}
            {!placeOrderClicked ? (
                <div className='placeorder' onClick={()=>backHandle(true)} >Place Order</div>
            ):(
                <div className='placeorder' onClick={checkout}>Checkout</div>
            )
            }

            
            {showPopup && (
                <Popup closePopup = {popupCloseHandle}/>
            )}
        </div> 
    )
}

export default Home