import React from 'react';
import './FoodItems.css';
import useFoodStore from './hooks/foodstore.js';

const FoodItems = ({ name, price }) => {

    const { selectedFoods, addFoodItem, removeFoodItem, updateQuantity } = useFoodStore();
    const foodItem = selectedFoods.find((item) => item.name === name);

    const handleAddClick = () => {
        addFoodItem({ name, price, quantity: 1 });
    };

    const handleMinusClick = () => {
        if (foodItem.quantity > 1) {
            updateQuantity(name, foodItem.quantity - 1);
        } else {
            removeFoodItem(name);
        }
    };

    const handlePlusClick = () => {
        updateQuantity(name, foodItem.quantity + 1);
    };

    return (
        <div className='food-item'>
            <div className='food-item-name'>{name}</div>
            <div className='food-quantity-container'>
                {foodItem && foodItem.quantity > 0 && (
                <>
                    <div className='quantity-button' onClick={handleMinusClick}> - </div>
                    <div className='selected-quantity'>{foodItem.quantity}</div>
                    <div className='quantity-button' onClick={handlePlusClick}> + </div>
                </>
                )}
                {!foodItem && (
                    <div className='add-button' onClick={handleAddClick}> Add </div>
                )}
            </div>
            <div className='price'>₹ {price}</div>
        </div>
    );
};

export default FoodItems;
