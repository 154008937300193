import './Popup.css'
import manImg from '../images/man.webp'
const Popup = ({closePopup}) => {
    return (
        <div className='popup' onClick={()=>closePopup(true)}>
            <div className='popup-content'>
                <img className='popup-man' src={manImg} loading='lazy' alt='popup-man'  />
                <h1>YOUR ORDER IS SUCCESSFULLY PLACED</h1>
                <h2>THANK YOU</h2>
            </div>
        </div>
  )
}

export default Popup