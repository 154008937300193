import './App.css'
import Home from './components/Home'

const App = () => {
    return (
        <>
            <Home/>
        </>
  )
}

export default App